import GatsbyImage from "gatsby-image"
import React from "react"
import PropTypes from "prop-types"

import { PageTitle } from "../SiteComponents"

export const VirtualTourTitle = ({ children }) => {
  return (
    <h1 className="text-white text-center mt-32 mb-10 leading-none text-4xl md:text-6xl">
      {children}
    </h1>
  )
}

export const VRGoggles = ({ fluid }) => {
  return (
    <div className="flex flex-col md:flex-row items-center md:items-start">
      <p>
        If you want to get the full experience try using a pair of Virtual
        Reality (VR) goggles! If you don’t have your own, contact your Tribal
        Council representative to get a free pair (cardboard) that can be used
        with most smartphones.
      </p>
      <div className=" w-full max-w-xs mb-10 ml-0 md:ml-2 shadow-md  ">
        <GatsbyImage fluid={fluid} />
      </div>
    </div>
  )
}

export const ImageAndText = ({
  fixed = null,
  text = "",
  isLeft = true,
  noFloat = false,
}) => {
  return (
    <div className="grid sm:inline-block mb-5 ">
      <div
        className={`${
          noFloat
            ? "  justify-self-center self-center "
            : isLeft
            ? "sm:float-left sm:mr-5"
            : "sm:float-right sm:ml-5"
        }  justify-self-center `}
      >
        <GatsbyImage fixed={fixed} className="mb-3 sm:mb-0" />
      </div>
      {text}
    </div>
  )
}

ImageAndText.defaultProps = {
  isLeft: true,
  noFloat: false,
}

ImageAndText.propTypes = {
  fixed: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  isLeft: PropTypes.bool,
  noFloat: PropTypes.bool,
}

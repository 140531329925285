import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import GatsbyImage from "gatsby-image"
import { FaHandPointer } from "react-icons/fa"
import { MdRefresh } from "react-icons/md"
import { BsArrowRightCircle } from "react-icons/bs"
import Layout from "../../components/layout"
import SEO from "../../components/SEO"
import BackgroundImage from "gatsby-background-image"
import { PageTitle } from "../../components/SiteComponents"
import {
  VirtualTourTitle,
  VRGoggles,
  ImageAndText,
} from "../../components/vr/VirtualTourComponents"

const HiddenLakeLandfill = ({ title }) => {
  const data = useStaticQuery(graphql`
    query HiddenLakeQuery {
      background: file(relativePath: { eq: "virtualTours.jps.jpg" }) {
        childImageSharp {
          fluid(quality: 50, maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tourPhoto: file(
        relativePath: {
          eq: "virtualTours/hiddenLakeLandfill/SiouxLookoutLandfillTour.png"
        }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      back: file(
        relativePath: { eq: "virtualTours/hiddenLakeLandfill/back.png" }
      ) {
        childImageSharp {
          fixed(quality: 100, width: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      learnMore: file(
        relativePath: { eq: "virtualTours/hiddenLakeLandfill/learnMore.jpg" }
      ) {
        childImageSharp {
          fixed(quality: 100, width: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      startTour: file(
        relativePath: { eq: "virtualTours/hiddenLakeLandfill/startTour.png" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      goggles: file(
        relativePath: { eq: "virtualTours/hiddenLakeLandfill/goggles.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const backgroundFluidImageStack = [
    data.background.childImageSharp.fluid,
    `linear-gradient(rgba(0, 117, 86, 0.65), rgba(0, 117, 86, 0.65))`,
  ].reverse()
  return (
    <Layout className="virtual-tour">
      <SEO title={title} />
      <BackgroundImage
        Tag="section"
        className=" flex-grow pb-20 flex flex-col"
        fluid={backgroundFluidImageStack}
      >
        <div className="text-white grid justify-items-center px-3 text-xl leading-tight max-w-6xl self-center  ">
          <VirtualTourTitle>{title}</VirtualTourTitle>
          <VRGoggles fluid={data.goggles.childImageSharp.fluid} />

          <p>
            Click on the link below to take a virtual tour of the Sioux Lookout
            Hidden Lake Landfill. Best to use Internet Browsers: Chrome, Safari,
            Firefox, Edge, and some versions of Explorer. You can visit their
            website for more information on site operations and hours:{" "}
            <a
              href="https://www.siouxlookout.ca/en/living-here/hidden-lake-landfill-site.asp"
              target="_blank"
              rel="noopener noreferrer"
              className=" hover:font-bold underline"
            >
              Hidden Lake Landfill Site - Sioux Lookout
            </a>
            .
          </p>
          <a
            href="https://hiddenlakevr.ca/"
            title="Virtual Tour of Hidden Lake Landfill, Sioux Lookout"
            target="_blank"
            rel="noopener noreferrer"
            className=" relative w-full max-w-xl shadow-lg  rounded-md overflow-hidden"
          >
            <div className="absolute flex flex-col items-center  justify-center top-0 right-0 bottom-0 left-0 bg-gray-900 z-10 bg-opacity-50 hover:bg-opacity-75 text-3xl font-bold sm:tracking-wide hover:text-nofnecGreen ">
              <span className="flex">
                Take the tour
                <BsArrowRightCircle className="ml-3 sm:ml-5 self-center" />
              </span>
              <div className="text-base font-normal italic">
                (opens in new window)
              </div>
            </div>
            <GatsbyImage
              fluid={data.tourPhoto.childImageSharp.fluid}
              className="relative"
            />
          </a>
          <section className="mt-10">
            <h2 className="text-center">Instructions</h2>
            <p>
              Please be patient, it may be slow to load and you may have to
              refresh/reload <MdRefresh className="inline" /> the page.
            </p>
            <p>
              Once loaded, you can use your mouse{" "}
              <FaHandPointer className="inline" /> (click and hold) to move
              around the facility.
            </p>

            <ImageAndText
              fixed={data.back.childImageSharp.fixed}
              text="To get back to the main view, click the ‘back’ banner; you may have to scroll around to find it though!"
            />
            <ImageAndText
              fixed={data.learnMore.childImageSharp.fixed}
              text="For each area of the Landfill there are icons you can click on to get a better look or hear more information."
              isLeft={false}
            />
            <p>
              We suggest starting your tour by clicking on the top right sun on
              the main screen. You will get lots of information from Andrew
              Jewell (Public Works Manager at the time).
            </p>

            <div className=" grid justify-items-center mb-3 sm:mb-5">
              <GatsbyImage
                fluid={data.startTour.childImageSharp.fluid}
                className="w-full max-w-xl"
              />
            </div>

            <p>
              Then move down to the entrance (bottom right icon) to visit the
              weigh scale and administration building.
            </p>
            <p>
              At each location there are several views and clips to listen to &
              learn more about:
            </p>
            <ul className="list-disc ">
              <li className="ml-16">Electronic waste</li>
              <li className="ml-16">Used tires</li>
              <li className="ml-16">Yard & clean wood waste</li>
              <li className="ml-16">Scrap metal</li>
              <li className="ml-16">Hazardous waste & waste oil</li>
              <li className="ml-16">Landfill waste cell</li>
              <li className="ml-16">
                Contaminant attenuation zone & groundwater monitoring wells
              </li>
            </ul>
          </section>
        </div>
      </BackgroundImage>
    </Layout>
  )
}

HiddenLakeLandfill.defaultProps = {
  title: "Virtual Tour of Hidden Lake Landfill Site in Sioux Lookout ",
  element1: <div>Photo should go here</div>,
}

HiddenLakeLandfill.propTypes = {
  title: PropTypes.string.isRequired,
  element1: PropTypes.element.isRequired,
}

export default HiddenLakeLandfill
